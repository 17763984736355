@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
    font-family: 'Unbounded-Light';
    src: url('../public/fonts/Unbounded/Unbounded-Light.woff2') format('woff2');
    font-weight: 300;
  }
  
  @font-face {
    font-family: 'Unbounded-Regular';
    src: url('../public/fonts/Unbounded/Unbounded-Regular.woff2') format('woff2');
    font-weight: 400;
  }
  
  @font-face {
    font-family: 'Unbounded-Bold';
    src: url('../public/fonts/Unbounded/Unbounded-Bold.woff2') format('woff2');
    font-weight: 700;
  }
  
  @font-face {
    font-family: 'Unbounded-Black';
    src: url('../public/fonts/Unbounded/Unbounded-Black.woff2') format('woff2');
    font-weight: 800;
  }
  
body {
    background-color: black;
    font-family: 'Unbounded', sans-serif;
}
